import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import leftArrow from "../../images/arrow-left.svg"
import rightArrow from "../../images/arrow-right.svg"
import styled from "styled-components"

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 27% 40%;
  align-content: center;
  justify-content: space-evenly;
  margin: 60px 0 100px 0;
  font-family: "Montserrat", sans-serif;
  grid-column-gap: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 27% 50%;
  }
  @media (max-width: 960px) {
    grid-template-columns: 30% 55%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 80%;
    grid-gap: 20px;
  }
`
const CardContentContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 20% 55%;
  justify-content: space-evenly;
  align-content: center;
  padding: 20px 0;
  border-radius: 30px;
  border: 1px solid #c2c8ce;
  transition: all 0.4 ease;
  min-height: 250px;
  @media (max-width: 768px) {
    grid-template-columns: 15% 60%;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
  }
`

const ImageSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-content: center;
  @media (max-width: 575px) {
    grid-template-columns: 70px;
  }
`

const TextContainer = styled.div`
  display: grid;
  align-content: center;
  justify-content: start;
  @media (max-width: 768px) {
    justify-content: center;
    grid-template-columns: auto;
  }
  @media (max-width: 575px) {
    text-align: center;
    padding: 0 15px;
    padding: 0 20px;
  }
`
const ContentH3 = styled.h3`
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 700;
  color: rgba(22, 31, 50, 1);
  @media (max-width: 675px) {
    font-size: 18px;
  }
`

const CardText = styled.p`
  color: #3d4d69;
  font-size: 18px;
  margin-bottom: 5px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    text-align: ${props => props.align && `center`};
  }
`
const CardDate = styled.p`
  color: #33475b;
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 33px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`
const PersonInfo = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-content: center;
  grid-column-gap: 20px;
  @media (max-width: 575px) {
    justify-content: space-between;
  }
`

const PersonName = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: rgba(22, 31, 50, 1);
  margin-top: 30px;
  margin-bottom: 0;
`
const StyledA = styled.a`
  text-decoration: none;
  color: #008faa;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const ReviewButtongrid = styled.div`
  display: grid;
  justify-content: start;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const ReviewButton = styled.div`
  font-size: 18px;
  padding: 13px 25px;
  color: #33cdd1;
  border-radius: 6px;
  border: 1px solid #33cdd1;
  white-space: nowrap;
  text-align: center;
  margin-top: 40px;
  &:hover {
    background: #33cdd1;
    border: 1px solid #33cdd1;
    color: #fff;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    margin-bottom: 30px;
  }
`
const LeftRoundButton = styled.div`
  position: absolute;
  top: 42%;
  left: -18px;
  font-size: 24px;
  color: white;
  background-color: ${props => (props.selector === 0 ? `#c2c8ce` : `#33cbcf`)};
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  cursor: pointer;
`
const RightRoundButton = styled.div`
  position: absolute;
  top: 42%;
  right: -19px;
  font-size: 24px;
  color: white;
  background-color: ${props => (props.selector === 4 ? `#c2c8ce` : `#33cbcf`)};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
`
const Dots = styled.div`
  position: absolute;
  bottom: -30px;
  left: 40%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 12px;
`
const Dot1 = styled.div`
  width: ${props => (props.selector === 0 ? `22px` : `7px`)};
  height: 7px;
  background-color: ${props => (props.selector === 0 ? `#33cbcf` : `#c2c8ce`)};
  border-radius: ${props => (props.selector === 0 ? `30px` : `50%`)};
  transition: all 0.4s ease;
`
const Dot2 = styled.div`
  width: ${props => (props.selector === 1 ? `22px` : `7px`)};
  height: 7px;
  background-color: ${props => (props.selector === 1 ? `#33cbcf` : `#c2c8ce`)};
  border-radius: ${props => (props.selector === 1 ? `30px` : `50%`)};
  transition: all 0.4s ease;
`
const Dot3 = styled.div`
  width: ${props => (props.selector === 2 ? `22px` : `7px`)};
  height: 7px;
  background-color: ${props => (props.selector === 2 ? `#33cbcf` : `#c2c8ce`)};
  border-radius: ${props => (props.selector === 2 ? `30px` : `50%`)};
  transition: all 0.4s ease;
`
const Dot4 = styled.div`
  width: ${props => (props.selector === 3 ? `22px` : `7px`)};
  height: 7px;
  background-color: ${props => (props.selector === 3 ? `#33cbcf` : `#c2c8ce`)};
  border-radius: ${props => (props.selector === 3 ? `30px` : `50%`)};
  transition: all 0.4s ease;
`
const Dot5 = styled.div`
  width: ${props => (props.selector === 4 ? `22px` : `7px`)};
  height: 7px;
  background-color: ${props => (props.selector === 4 ? `#33cbcf` : `#c2c8ce`)};
  border-radius: ${props => (props.selector === 4 ? `30px` : `50%`)};
  transition: all 0.4s ease;
`
const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25%;
  grid-gap: 40px;
  justify-content: center;
  margin-bottom: 80px;
  @media (max-width: 800px) {
    grid-template-columns: 42% 42%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 80%;
    grid-gap: 20px;
  }
`
const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 55%;
  position: relative;
  height: 0;
`
const StyledIframe = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

export const TestimonialIndex = ({ data, alternative }) => {
  const [testiText, settestiText] = useState([
    {
      id: 0,
      textP1: `For a long time we have been looking for a solution like ‘Groupboss’.
      It’s an absolute lifesaver for us.Groupboss will help you to retain 
      your data forever,there is no chance of losing any data.`,
      personName: `Abdul A Mukati`,
    },
    {
      id: 1,
      textP1: `Those E-mails are gold. The missing piece of software that I
      needed to grow my E-mail lists. If you are trying to build
      an active Facebook group then it’s a must having tool.`,
      personName: `Bruce Kraft Jr.`,
    },
    {
      id: 2,
      textP1: `Highly recommend it for anyone who has a business on the internet. Groupboss is the most effective way, which I've I never think of it before, to grow your email list consistently from Facebook group. It's just a simple tactic but works like a charm. I love it too much and I regret why I know it too late!`,
      personName: `Cường Trần`,
      textP2: `Mar 25, 2020`,
    },
    {
      id: 3,
      textP1: `Omar and his team make a strong effort to solve every problem they are very very accessible. The UI is very clean and intuitive .  It the better of all of the other systems I purchased. This product is ready for the big time...  i dont work for them and i actually paid for the program`,
      personName: `Trevor Otts`,
      textP2: `Apr 12, 2020`,
    },
    {
      id: 4,
      textP1: `Hey Groupboss Team, I just wanted to take a second to thank you for the excellent support I just had! I had a problem with the extension and dealing with a growing group so I had this to be fixed asap! You contacted me and took the time to troubleshoot my bug and it really made my day! Thanks again and keep up the good work.`,
      personName: `Alexandre P`,
      textP2: `Mar 15, 2020`,
    },
  ])
  const [selector, setSelector] = useState(0)
  const [buttonActive, setButtonActive] = useState(false)
  const leftSelector = () => {
    selector > 0 && setSelector(selector => selector - 1)
    setButtonActive(true)
  }
  const rightSelector = () => {
    selector < 4 && setSelector(selector => selector + 1)
    setButtonActive(true)
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (!buttonActive) {
        if (selector < 4) {
          setSelector(selector => selector + 1)
        } else {
          setSelector(0)
        }
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [selector, buttonActive])

  return (
    <>
      <ContentContainer>
        <TextContainer>
          <ContentH3>People around the world love us</ContentH3>
          <ReviewButtongrid>
            <GatsbyImage
              image={data.fiveStar.childImageSharp.gatsbyImageData}
            />
          </ReviewButtongrid>
          {/* <CardText align >almost 5 star rating from our customer</CardText> */}
          <ReviewButtongrid>
            <ReviewButton>
              <StyledLink to="/testimonials">View all feedbacks</StyledLink>
            </ReviewButton>
          </ReviewButtongrid>
        </TextContainer>

        <CardContentContainer>
          <LeftRoundButton onClick={() => leftSelector()} selector={selector}>
            <img src={leftArrow} alt="left arrow icon" />
          </LeftRoundButton>
          <ImageSection>
            {selector === 0 ? (
              <GatsbyImage
                image={data.client.childImageSharp.gatsbyImageData}
                style={{ borderRadius: `50%` }}
              />
            ) : selector === 1 ? (
              <GatsbyImage
                image={data.client_1.childImageSharp.gatsbyImageData}
                style={{ borderRadius: `50%` }}
              />
            ) : selector === 2 ? (
              <GatsbyImage
                image={data.client_2.childImageSharp.gatsbyImageData}
                style={{ borderRadius: `50%` }}
              />
            ) : selector === 3 ? (
              <GatsbyImage
                image={data.client_3.childImageSharp.gatsbyImageData}
                style={{ borderRadius: `50%` }}
              />
            ) : (
              <GatsbyImage
                image={data.client_4.childImageSharp.gatsbyImageData}
                style={{ borderRadius: `50%` }}
              />
            )}
          </ImageSection>
          <TextContainer>
            <div>
              {testiText[selector].textP1 && (
                <CardText>{testiText[selector].textP1} </CardText>
              )}
              <PersonInfo>
                <PersonName>{testiText[selector].personName}</PersonName>
                {testiText[selector].textP2 && (
                  <CardDate>{testiText[selector].textP2} </CardDate>
                )}
              </PersonInfo>
            </div>
          </TextContainer>
          <RightRoundButton onClick={() => rightSelector()} selector={selector}>
            <img src={rightArrow} alt="right arrow icon" />
          </RightRoundButton>
          <Dots>
            <Dot1 selector={selector} />
            <Dot2 selector={selector} />
            <Dot3 selector={selector} />
            <Dot4 selector={selector} />
            <Dot5 selector={selector} />
          </Dots>
        </CardContentContainer>
      </ContentContainer>

      {alternative !== "groupleads" && (
        <>
          <VideoGrid>
            <VideoResponsive>
              <StyledIframe
                src="https://www.youtube.com/embed/s7T9oo30N6c"
                title="Collect Emails from your facebook groups and grow email lists like a champ"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </VideoResponsive>
            <VideoResponsive>
              <StyledIframe
                src="https://www.youtube.com/embed/lBJOHwOs2us"
                title="Collect Emails from your facebook groups and grow email lists like a champ"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </VideoResponsive>
            <VideoResponsive>
              <StyledIframe
                src="https://www.youtube.com/embed/GdHTKz_oor0"
                title="Collect Emails from your facebook groups and grow email lists like a champ"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              />
            </VideoResponsive>
          </VideoGrid>
        </>
      )}
    </>
  )
}
export default props => (
  <StaticQuery
    query={graphql`
      {
        fiveStar: file(relativePath: { eq: "fiveStar.png" }) {
          childImageSharp {
            gatsbyImageData(width: 180, height: 25, layout: FIXED)
          }
        }
        client: file(relativePath: { eq: "abdul_a_mukati.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        client_1: file(relativePath: { eq: "BruceKraftJr.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        client_2: file(relativePath: { eq: "client2.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        client_3: file(relativePath: { eq: "client3.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        client_4: file(relativePath: { eq: "shrekanth.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={data => <TestimonialIndex data={data} {...props} />}
  />
)
